<template>
  <SharePageTemplate>
    <template v-slot:image-content>
      <video
        autoplay
        preload="metadata"
        controls
        style="width: 100%; height: 100%; border-radius: 5px !important"
        muted
      >
        <source
          :src="$route.query.link"
          type="video/mp4"
          codecs="avc1.4d002a, mp4a.40.2"
        />
      </video>
    </template>
  </SharePageTemplate>
</template>

<script>
import SharePageTemplate from '@/components/share/SharePageTemplate.vue';

export default {
  name: 'SharedVideoView',
  components: {SharePageTemplate},
};
</script>
